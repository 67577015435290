import request from 'shared/request';
// import BadRequestError from 'errors/BadRequestError';

export const getCities = async () => {
  try {
    const response = await request.get('/cities');
    return response;
  } catch (error) {
    //TODO
  }
};

export const getCitiesByState = async (state) => {
  try {
    const response = await request.get(`/ibge/states/${state}/cities`);
    return response.data;
  } catch (error) {
    //TODO
  }
};

export const getMunicipalitiesByState = async (state) => {
  try {
    const response = await request.get(`/ibge/states/${state}/municipalities`);
    return response.data;
  } catch (error) {
    //TODO
  }
};
