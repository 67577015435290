import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import createStyles from './CustomTitle.styles';

const CustomTitle = props => {
  const classes = createStyles(props);
  const { title, subtitle, className } = props;

  return (
    <div className={`${classes.titleContainer} ${className}`}>
      <Typography variant='h1' className={classes.titleBar}>
        /
      </Typography>
      <div>
        <Typography variant='body1' className={classes.subtitle}>
          {subtitle}
        </Typography>
        <Typography variant='h4' className={classes.title}>
          {title}
        </Typography>
      </div>
    </div>
  );
};

CustomTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  className: PropTypes.string
};

export default CustomTitle;
