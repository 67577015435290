import { getCourseDetails, getFilters } from 'api/CourseDetails';

// Actions
const FETCH_COURSE_DETAILS_START = 'sesi/login/FETCH_COURSE_DETAILS_START';
const FETCH_COURSE_DETAILS_SUCCESS = 'sesi/login/FETCH_COURSE_DETAILS_SUCCESS';
const FETCH_COURSE_DETAILS_FAILURE = 'sesi/login/FETCH_COURSE_DETAILS_FAILURE';
const FETCH_FILTERS_START = 'sesi/FETCH_FILTERS_START';
const FETCH_FILTERS_SUCCESS = 'sesi/FETCH_FILTERS_SUCCESS';
const FETCH_FILTERS_FAILURE = 'sesi/FETCH_FILTERS_FAILURE';
const COURSE_DETAILS_RESET = 'sesi/COURSE_DETAILS_RESET';
const SET_FAVORITE_COURSE = 'sesi/SET_FAVORITE_COURSE';

// Reducer
const initialState = {
  course: {},
  relatedCourses: [],
  navigationHistory: [],
  isLoading: false,
  isLoadinCourseDetail: false,
  filters: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_COURSE_DETAILS_START:
      return { ...state, course: {}, relatedCourses:[], navigationHistory:[], filters:[] ,isLoadinCourseDetail: true };
    case FETCH_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        course: action.payload,
        isLoadinCourseDetail: false
      };
    case FETCH_COURSE_DETAILS_FAILURE:
      return { ...state, ...action.payload, isLoadinCourseDetail: false };
    case FETCH_FILTERS_START:
      return { ...state, isLoading: true };
    case FETCH_FILTERS_SUCCESS:
      return { ...state, filters: action.payload, isLoading: false };
    case FETCH_FILTERS_FAILURE:
      return { ...state, ...action.payload };
    case COURSE_DETAILS_RESET:
      return { ...state, ...initialState };
    case SET_FAVORITE_COURSE:
      let newCourse = state.course;
      newCourse.favorite = action.payload.value
      return { ...state, course: newCourse };
    default:
      return state;
  }
}

// Action Creators
export function fetchCourseDetailsStart() {
  return { type: FETCH_COURSE_DETAILS_START };
}

export function fetchCourseDetailsSuccess(data) {
  return { type: FETCH_COURSE_DETAILS_SUCCESS, payload: data };
}

export function fetchCourseDetailsFailure(data) {
  return { type: FETCH_COURSE_DETAILS_FAILURE, payload: data };
}

export function fetchFiltersStart() {
  return { type: FETCH_FILTERS_START };
}

export function fetchFiltersSuccess(data) {
  return { type: FETCH_FILTERS_SUCCESS, payload: data };
}

export function fetchFiltersFailure(data) {
  return { type: FETCH_FILTERS_FAILURE, payload: data };
}

export function reset() {
  return { type: COURSE_DETAILS_RESET };
}

// side effects, only as applicable
export const fetchCourseDetails = (sku) => {
  return async (dispatch) => {
    dispatch(fetchCourseDetailsStart());

    try {
      const response = await getCourseDetails(sku);
      dispatch(fetchCourseDetailsSuccess(response));
    } catch (error) {
      dispatch(fetchCourseDetailsFailure(error));
    }
  };
};

export const fetchFilters = () => {
  return async (dispatch) => {
    dispatch(fetchFiltersStart());

    try {
      const response = await getFilters();
      dispatch(fetchFiltersSuccess(response));
    } catch (error) {
      dispatch(fetchFiltersFailure(error));
    }
  };
};

export const setFavoriteToCourse = (sku, value) => {
  return { type: SET_FAVORITE_COURSE, payload: {sku, value} };
}