import React, { useEffect } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import Carousel from '../../components/Carousel/Carousel';
import CategoryDescription from '../../components/CategoryDescription/CategoryDescription';
import CarouselBanner from 'components/CarouselBanner';
import ButtonPrimary from 'components/ButtonPrimary';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomTitle from 'components/CustomTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MainContainer from 'components/MainContainer';
import LinksBar from 'components/LinksBar';
import { toUrlFriendly } from 'shared/util/textTransformation.js';
import theme from 'App.theme.js';
import SelectSearch from 'components/SelectSearch';

import createStyles from './Vitrine.styles';

const Vitrine = (props) => {
  const { fetchVitrine } = props;
  // const [openLoginBox, setOpenLoginBox] = useState(false);
  const {
    featuredProducts,
    categories,
    banners,
    cities,
    isLoading
  } = props.vitrine;

  const classes = createStyles(props);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const breadcrumbs = [{ label: 'Início', to: '/' }];

  // const showLoginBox = () => {
  //   setOpenLoginBox(true);
  // }

  // const closeLoginBox = () => {
  //   setOpenLoginBox(false);
  // }

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchVitrine();
  }, []);

  return (
    <React.Fragment>
      <Breadcrumbs items={breadcrumbs} />

      {isLoading ? (
        <div className={classes.rootLoading}>
          <CircularProgress />
        </div>
      ) : (
        <React.Fragment>
          <CarouselBanner bannerList={banners} />
          <div className={classes.cityContainer}>
            <CustomTitle
              className={classes.cityFilterTitle}
              title='Escolha a Unidade'
              subtitle='Encontre cursos próximos a você'
            />
          </div>

          {isMobile ? (
            <SelectSearch
              className={classes.selectSearch}
              redirect
              options={
                cities &&
                cities.map((city) => {
                  return {
                    label: city.name,
                    value: city.name,
                    url: `/cursos/cidade/${toUrlFriendly(city.name)}/${city.id}`
                  };
                })
              }
              icon={'city'}
              placeholder={'Cidade'}
            />
          ) : (
            <LinksBar
              overrideClasses={classes.linksBar}
              items={
                cities &&
                cities.map((city) => {
                  return {
                    title: city.name.toUpperCase(),
                    url: `/cursos/cidade/${toUrlFriendly(city.name)}/${city.id}`
                  };
                })
              }
            />
          )}
          {featuredProducts && featuredProducts.length > 0 && (
            <MainContainer padding bgColor={theme.colors.background.bg03}>
              <div style={{marginTop: '3.5rem'}}>
              <Carousel
                title='cursos em destaque'
                subtitle='Seleção'
                courses={featuredProducts}
              />
              </div>
            </MainContainer>
          )}

          {categories &&
            categories.map((categorie, index) => {
              return (
                <div id={categorie.elementId} key={categorie.id}>
                  <CategoryDescription
                    image={categorie.image}
                    title={categorie.title}
                    subtitle={'Conheça os cursos'}
                    text={categorie.description}
                    openLinkCurrentWindow
                    link={`/categoria/${categorie.id}`}
                    buttonText={'Ver cursos'}
                    backgroundColor={
                      index % 2 === 0
                        ? theme.colors.background.white
                        : theme.colors.background.bg03
                    }
                    textFirst={index % 2 === 0 ? false : true}
                  />

                  <MainContainer
                    bgColor={
                      index % 2 === 0
                        ? theme.colors.background.white
                        : theme.colors.background.bg03
                    }
                  >
                    <Carousel
                      courses={categorie.products}
                      showAllBtn={<ButtonPrimary>VER TODOS</ButtonPrimary>}
                      showLoginBox={props.openLoginBox}
                      closeLoginBox={props.closeLoginBox}
                    />
                  </MainContainer>
                </div>
              );
            })}
        </React.Fragment>
      )}
      {/* <AuthenticationBox
        isModal
        isLoginBoxOpen={openLoginBox}
      /> */}
    </React.Fragment>
  );
};

export default Vitrine;
