import request, { setHeaderAuth } from 'shared/request';
import { getAuthToken } from 'shared/util/LocalStorage';

export const getFavorites = async (email) => {
  try {
    const response = await request.get(`/favorites`);
    return response.data;
  } catch (error) {
    //TODO
  }
};

//mudar
export const setFavorite = async (sku, email, value) => {
  try {
    const response = await request.post('/favorites', { sku, email, value });
    return response.data;
  } catch (error) {
    //TODO
  }
};

export const hasFavorite = async (sku, email) => {
  try {
    const response = await request.post('/favorites/hasFavorite', {
      sku,
      email
    });
    return response.data;
  } catch (error) {
    //TODO
  }
};