import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles(theme => ({
    root: {
      padding: '.5rem 0',
      backgroundColor: theme.palette.background.paper,
      fontSize: '1.4rem'
    }
  }));

  export default createStyles;