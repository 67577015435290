import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#22498D',
      contrastText: '#fff'
    },
    secondary: {
      main: '#0377BE',
      contrastText: '#fff'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.65)',
      secondary: 'rgba(0, 0, 0, 0.45)',
      disabled: 'rgba(0, 0, 0, 0.30)',
      hint: 'rgba(0, 0, 0, 0.30)'
    }
  },
  spacing: 8,
  status: {
    danger: 'orange'
  },
  colors: {
    white: '#FFFFFF',
    blueCompany: '#22498D',
    blueEducation: '#2454A0',
    blueHover: '#193B75',
    blueLink: '#32559D',
    blueLight: '#27AAE1',
    cyanYou: '#18A89A',
    cyanDark: '#008A71',
    redCulture: '#E44C25',
    redSesi: '#FC0D1B',
    yellowHealthCare: '#FEC111',
    yellowTag: '#F9B233',
    purpleBlog: '#783E71',
    purpleDark: '#4D2780',
    divider: '#E5E5E5',
    dividerBlue: '#CADDED',
    border: {
      light: '#22498D',
      medium: '#22498D',
      dark: '#22498D'
    },
    alerts: {
      error: '#D31823',
      success: '#D31823',
      warning: '#D31823'
    },
    background: {
      bg01: 'rgba(0, 0, 0, .01)',
      bg02: 'rgba(0, 0, 0, .02)',
      bg03: 'rgba(0, 0, 0, .05)',
      bg04: 'rgba(0, 0, 0, .04)',
      bg05: 'rgba(0, 0, 0, .05)',
      bg06: 'rgba(0, 0, 0, .06)',
      bg07: 'rgba(0, 0, 0, .07)',
      bg08: 'rgba(0, 0, 0, .08)',
      bg09: 'rgba(0, 0, 0, .09)',
      bg10: 'rgba(0, 0, 0, 0.1)',
      bg45: 'rgba(0, 0, 0, 0.45)',
      bg30: 'rgba(0, 0, 0, 0.30)',
      bg65: 'rgba(0, 0, 0, 0.65)'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.65)',
      secondary: 'rgba(0, 0, 0, 0.45)',
      disabled: 'rgba(0, 0, 0, 0.30)',
      dark: 'rgba(0, 0, 0, .8)',
      miniTextLight: '#A3BACA'
    }
  },
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
    fontSize: 16,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '4rem'
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '3.6rem'
    },
    h3: {
      fontSize: '2.6rem'
    },
    h4: {
      fontWeight: 'bold',
      fontSize: '2.6rem'
    },
    h5: {
      fontSize: '2.4rem'
    },
    h6: {
      fontSize: '2.2rem'
    },
    subtitle1: {
      fontSize: '2.0rem'
    },
    subtitle2: {
      fontSize: '1.8rem'
    },
    body1: {
      fontSize: '1.6rem'
    },
    body2: {
      // height: '1.4rem',
      fontSize: '1.4rem'
    },
    body3: {
      // height: '1.4rem',
      fontSize: '0.6rem'
    },
    button: {
      fontSize: '1.8rem'
    },
    caption: {
      fontSize: '1.4rem'
    }
  }
});

export default theme;
