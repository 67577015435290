import React from 'react';
import PropTypes from 'prop-types';
import createStyles from './LinksBar.styles';
import { Link } from 'react-router-dom';

const LinksBar = (props) => {
  const classes = createStyles(props);
  const { items, overrideClasses } = props;

  if (!items) return null;

  return (
    <div className={`${classes.rootLinksBar} ${overrideClasses}`}>
      <ul className={classes.list}>
        {items.map((item, index) => {
          return (
            <li key={index}>
              <Link to={{pathname: item.url, state: {cityName: item.title}}}>{item.title} </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

LinksBar.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  overrideClasses: PropTypes.string
};

export default LinksBar;
