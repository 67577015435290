import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  bannerContent: {
    padding: '12rem 27rem',
    maxWidth: '44rem',
    [theme.breakpoints.down('md')]: {
      padding: '10rem 20rem'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6rem'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10%'
    }
  },
  rootLoading: {
    height: 'calc(100vh - 7rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cityContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  linkCardsContainer: {
    display: 'inline-flex',
    justifyContent: 'center',
    marginTop: '3rem',
    width: '100%',
    '& div': {
      margin: '0 1rem'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  linksBar: {
    marginTop: '3rem',
  },
  selectSearch: {
    margin: '3rem'
  },
  cityFilterTitle: {
    marginTop: '3rem',
    width: '98%',
    display: 'flex',
    position: 'relative',
  }
}));

export default createStyles;
