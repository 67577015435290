import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import LoginBox from './LoginBox';
import RegisterBox from './RegisterBox';
import { Backdrop } from '@material-ui/core';

import createStyles from './AuthenticationBox.styles';

const AuthenticationBox = (props) => {
  const classes = createStyles(props);
  const {
    className,
    isLoginBoxOpen,
    closeLoginBox,
    isLoading,
    isModal,
    callback,
    history,
    redirectAfterLogin
  } = props;

  const [registerBoxOpen, setRegisterBoxOpen] = useState(false);

  const handleUserKeyPress = useCallback((event) => {
    const { keyCode } = event;

    if (keyCode === 27) {
      closeLoginBox();
      setRegisterBoxOpen(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    if (!isLoginBoxOpen) setRegisterBoxOpen(false);
  }, [isLoginBoxOpen]);

  const handleClose = () => {
    closeLoginBox();
  };

  const toggleRegister = () => {
    setRegisterBoxOpen(!registerBoxOpen);
  };

  const renderContent = () => {
    const content = registerBoxOpen ? (
      <RegisterBox
        history={history}
        afterRegister={callback}
        className={classes.boxPosition}
        handleClose={handleClose}
        handleLoginClick={toggleRegister}
      />
    ) : (
      <LoginBox
        afterLogin={callback}
        handleClose={handleClose}
        handleRegisterClick={toggleRegister}
        className={classes.boxPosition}
        // redirectAfterLogin={redirectAfterLogin}
      />
    );

    if (isLoginBoxOpen && isModal) {
      return (
        <Backdrop
          open={isLoginBoxOpen}
          className={classes.backdrop}
          onClick={handleClose}
        >
          {content}
        </Backdrop>
      );
    } else if (isLoginBoxOpen && !isModal) {
      return content;
    }
  };

  return (
    <div className={`${classes.rootAuthenticatioBox} ${className}`}>
      {renderContent()}
    </div>
  );
};

AuthenticationBox.propTypes = {
  className: PropTypes.string,
  isLoginBoxOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  closeLoginBox: PropTypes.func,
  callback: PropTypes.func
};

export default AuthenticationBox;
