import RegisterInterest from './RegisterInterest.jsx';
import { connect } from 'react-redux';
import {registerinterestIn} from '../../../modules/Registration/Registration.ducks'
const mapStateToProps = state => {
  return {
    isLoading: state.userRegistration.isLoading, 
    success: state.userRegistration.success, 
  };
};

const mapDispatchToProps = {
  registerinterestIn
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterInterest);
