import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import RegisterForm from './Form/RegisterBoxForm.formik';
import createStyles from './RegisterBox.styles';

const RegisterBox = (props) => {
  const classes = createStyles();
  const {
    register,
    isLoading,
    handleClose,
    errors,
    handleLoginClick,
    afterRegister,
    history
  } = props;

  return (
    <Card
      onClick={(e) => e.stopPropagation()}
      elevation={props.elevation || 2}
      className={`${classes.rootRegisterBox} ${props.className}`}
    >
      <CardContent className={classes.cardContent}>
        <Typography
          variant='subtitle1'
          align='center'
          className={classes.title}
        >
          Cadastre-se
        </Typography>

        <RegisterForm
          history={history}
          isLoading={isLoading}
          errors={errors}
          register={register}
          handleClose={handleClose}
          afterRegister={afterRegister}
        />

        <Typography align='center' className={classes.registerCall}>
          Já tem uma conta?{' '}
          <Link to='#'
            onClick={handleLoginClick}
            className={classes.loginHook}>
            Faça login
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
};

RegisterBox.propTypes = {
  handleClose: PropTypes.func,
  handleLoginClick: PropTypes.func,
  register: PropTypes.func,
  elevation: PropTypes.number
};

export default RegisterBox;
