import Vitrine from './Vitrine.view';
import { connect } from 'react-redux';
import { fetchVitrine } from 'modules/Vitrine/Vitrine.ducks.js';
import { openLoginBox, closeLoginBox } from 'modules/Login/Login.ducks.js';

const mapStateToProps = state => {
  return {
    vitrine: state.vitrine,
    isLoginBoxOpen: state.session.ui.isLoginBoxOpen,
  };
};

const mapDispatchToProps = {
  fetchVitrine,
  openLoginBox,
  closeLoginBox, 
};

export default connect(mapStateToProps, mapDispatchToProps)(Vitrine);
