import * as Yup from 'yup';
import { validate as validateCPF } from 'gerador-validador-cpf';
import { validateCNPJ } from 'shared/util/ValidateCNPJ.js';

const registerForm = {
  cpfCNPJ: Yup.string()
    .required('Este campo é obrigatório')
    .test('validateCpf', 'CPF ou CNPJ inválido', function (value) {
      if (value) {
        if (value.replace(/\D/g, '').length <= 11) {
          return validateCPF(value);
        } else {
          return validateCNPJ(value);
        }
      }
    })
};

export const registerFormValidator = Yup.object().shape(registerForm);
