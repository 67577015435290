import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  rootRegisterBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: "1301",
    transform: 'translate(-50%, -50%)',
    borderRadius: 0,
    '@media (max-width: 767px)': {
      width: '100%'
    },
    boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.25)'

  },
  formBox: {
    display: 'flex',
    
    borderRadius: 0,
    '@media (max-width: 767px)': {
      width: '100%',
      justifyContent: 'center'
    },
  },
  courseInfo: {
    width: 370,
    '@media (max-width: 767px)': {
      display: 'none'
    },
  },
  loginHook: {
    color: theme.colors.blueLink
  },
  registerCall: {
    marginTop: '2rem'
  },
  cardContent: {
    width: 310,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.5rem 3rem'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer
  },
  title: {
    margin: '1rem 0',
    color: theme.colors.blueCompany,
  },
  messageText: {
    marginTop: '1.5rem',
    marginBottom: '1.5rem'
  },
  confirmIcon:{
    fontSize: '50px',
    color: theme.colors.blueCompany
  }
}));

export default createStyles;
