import React from 'react';

import ButtonPrimary from 'components/ButtonPrimary';
import CustomTitle from 'components/CustomTitle';

import createStyles from './CategoryDescription.styles';

const CategoryDescription = props => {
  const classes = createStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <img
          alt='Imagem da categoria'
          className={classes.image}
          src={props.image}
        />
      </div>
      <div className={classes.textContainer}>
        <CustomTitle title={props.title} subtitle={props.subtitle} />
        <p className={classes.text}> {props.text} </p>
        <ButtonPrimary openLinkCurrentWindow={props.openLinkCurrentWindow} link={props.link} buttonText={props.buttonText} />
      </div>
    </div>
  );
};

CategoryDescription.propTypes = {};

export default CategoryDescription;
