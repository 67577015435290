import moment from 'moment';

const PRODUCT_HISTORY = "product_history";
const PRODUCT_HISTORY_CREATE = "product_history_create";
const AUTH_TOKEN = "auth_token";
const CART_ITEM = "cart_item";

const courseToProduct = (course) => ({ ...course, place: course.classes && course.classes[0]?.place, classes: course.classes });

export const addCourseToCart = course => {
    if(course) {
        // localStorage.removeItem(CART_ITEM);
        let courses = JSON.parse(localStorage.getItem(CART_ITEM));
        if(courses){
            const filterCourses = courses.filter(item => item.product.sku === course.product.sku);
            if(filterCourses && filterCourses.length === 0){
                courses.push(course);
            }
        } else {
            courses = []; 
            courses.push(course);
        }

        localStorage.setItem(CART_ITEM, JSON.stringify(courses));
    }
}

export const deleteCourseFromCart = courseId => {
    // localStorage.removeItem(CART_ITEM);
    if(courseId) {
        let courses = JSON.parse(localStorage.getItem(CART_ITEM)); 
        const filteredCourses = courses.filter(course =>  course.product.sku !== courseId); 
        if(filteredCourses.length >= 1){
            localStorage.setItem(CART_ITEM, JSON.stringify(filteredCourses));
        } else {
            localStorage.removeItem(CART_ITEM);
        }
    }
}

export const deleteAllFromCart = () => {
    const storedCourses = getCartItems();

    storedCourses.forEach(course => {
        deleteCourseFromCart(course.courseId);
    });
}

export const getCartItems = () => {
    return JSON.parse(localStorage.getItem(CART_ITEM));
}

export const addProductToHistory = product => {
    if (product && product.sku) {
        let history = JSON.parse(localStorage.getItem(PRODUCT_HISTORY));
        if (history) {
            const filteredProducts = history.filter(item => item.sku === product.sku);
            if (filteredProducts && filteredProducts.length === 0) {
                history.push(courseToProduct(product));
            }
        } else {
            history = [];
            history.push(courseToProduct(product));
            localStorage.setItem(PRODUCT_HISTORY_CREATE, new Date());
        }

        localStorage.setItem(PRODUCT_HISTORY, JSON.stringify(history));
    }
}

export const getHistory = (sku) => {
    const createdDate = localStorage.getItem(PRODUCT_HISTORY_CREATE);
    if(createdDate){
        const now = moment(new Date());
        const created = moment(createdDate);
        
        if(now.diff(created,'days') > 1) {
            localStorage.removeItem(PRODUCT_HISTORY_CREATE);        
            localStorage.removeItem(PRODUCT_HISTORY);
        }
    }
    
    
    let history = JSON.parse(localStorage.getItem(PRODUCT_HISTORY));
    if(history && history.length > 0){
        return history.filter(item => item.sku !== sku);
    }

    return history;
}

export const setAuthToken = token => {
    localStorage.setItem(AUTH_TOKEN, token);
}

export const getAuthToken = () => {
    return localStorage.getItem(AUTH_TOKEN);
}

export const deleteAuthToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
}