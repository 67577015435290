import React from 'react';
import Select, { components } from 'react-select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PlaceIcon from '@material-ui/icons/Place';
import AppsIcon from '@material-ui/icons/Apps';
import SortIcon from '@material-ui/icons/Sort';
import PropTypes from 'prop-types';
import FilterListIcon from '@material-ui/icons/FilterList';
import theme from '../../App.theme';

const customStyles = (theme) => {
  const blue = theme.colors.blueCompany;
  const text = theme.palette.text.primary;
  const white = theme.colors.white;
  const border = theme.colors.dividir;

  return {
    option: (provided, state) => ({
      ...provided,
      padding: '1.3rem ',
      fontSize: '1.6rem ',
      borderBottom: `1px solid ${border} `,
      color: state.isFocused ? white : text,
      backgroundColor: state.isFocused ? blue : white
    }),

    placeholder: (provided) => ({
      ...provided,
      marginLeft: '4rem'
    }),

    singleValue: (provided) => ({
      ...provided,
      marginLeft: '4rem'
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      marginRight: '8px',
      color: text,
      transform: state.isFocused ? 'rotate(180deg)' : ''
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '3px',
      borderColor: `${theme.colors.text.secondary}`,
      borderWidth: '1px',
      borderStyle: 'solid',
      height: '5rem'
    }),

    menu: (provided) => ({
      ...provided,
      borderRadius: '0px',
      borderColor: `${theme.colors.white}`,
      borderWidth: '0px',
      borderStyle: 'transparent',
      marginTop: 0,
      zIndex: '2'
    }),

    menuList: (provided) => ({
      ...provided,
      padding: '0'
    }),

    valueContainer: (provided) => ({
      ...provided,
      fontSize: '1.6rem',
      display: 'flex',
      alignItems: 'center'
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
      backgroundColor: 'transparent'
    })
  };
};

const SelectSearch = (props) => {
  const { iconSize, onSelectChange, redirect } = props;

  const handleChange = (item) => {
    if (redirect) {
      window.location.href = item.url;
    } else {
      onSelectChange(item.value);
    }
  };

  const Placeholder = (props) => {
    return (
      <React.Fragment>
        {props.selectProps.icon === 'city' ? (
          <PlaceIcon
            fontSize={iconSize || 'large'}
            style={{ color: theme.colors.blueCompany }}
          />
        ) : props.selectProps.icon === 'area' ? (
          <AppsIcon
            fontSize={iconSize || 'large'}
            style={{ color: theme.colors.blueCompany }}
          />
        ) : props.selectProps.icon === 'sort' ? (
          <SortIcon
            fontSize={iconSize || 'large'}
            style={{ color: theme.colors.blueCompany }}
          />
        ) : props.selectProps.icon === 'filter' ? (
          <FilterListIcon
            fontSize={iconSize || 'large'}
            style={{ color: theme.colors.blueCompany }}
          />
        ) : null}
        <components.Placeholder {...props} />
      </React.Fragment>
    );
  };

  const SingleValue = (props) => {
    return (
      <React.Fragment>
        {props.selectProps.icon === 'city' ? (
          <PlaceIcon
            fontSize={iconSize || 'large'}
            style={{ color: theme.colors.blueCompany }}
          />
        ) : props.selectProps.icon === 'area' ? (
          <AppsIcon
            fontSize={iconSize || 'large'}
            style={{ color: theme.colors.blueCompany }}
          />
        ) : props.selectProps.icon === 'sort' ? (
          <SortIcon
            fontSize={iconSize || 'large'}
            style={{ color: theme.colors.blueCompany }}
          />
        ) : props.selectProps.icon === 'filter' ? (
          <FilterListIcon
            fontSize={iconSize || 'large'}
            style={{ color: theme.colors.blueCompany }}
          />
        ) : null}
        <components.SingleValue {...props} />
      </React.Fragment>
    );
  };

  const DropdownIndicator = (props) => {
    return <ArrowDropDownIcon fontSize={iconSize || 'large'} />;
  };

  return (
    <Select
      value={props.value}
      className={props.className}
      icon={props.icon}
      placeholder={props.placeholder}
      options={props.options}
      onChange={handleChange}
      components={{ Placeholder, SingleValue, DropdownIndicator }}
      styles={customStyles(theme)}
    />
  );
};

SelectSearch.propTypes = {
  iconSize: PropTypes.string,
  onSelectChange: PropTypes.func,
  redirect: PropTypes.bool
};

export default SelectSearch;
