import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    '& img': {
      width: '100%',
      height: (props) => (props.responsive ? 'unset' : '300px'),
      objectFit: 'cover',      
    },
    '& h1': {
      color: '#ffffff',
      width: '100%',
      fontSize: '3em',
      fontWeight: '200',
      textTransform: 'uppercase',
      '@media (max-width: 700px)': {
        left: '2px',
        width: '100%'
      }
    },
    '& p': {
      color: '#ffffff',
      width: '95%',
      fontSize: '1.75em',
      fontWeight: '200',
      marginTop: '12px',
      // '@media (min-width: 1024px)': {
      //   width: '50%'
      // }
    },
    '& div': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%'
    }
  },
  rootImage: {
    position: 'relative',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    '& img': {
      width: '100%',
      height: (props) => (props.responsive ? 'unset' : '300px'),
      objectFit: 'cover',      
    },
    '& h1': {
      color: '#ffffff',
      width: '100%',
      fontSize: '3em',
      fontWeight: '200',
      margin: '0',
      textAlign: 'center',
      textTransform: 'uppercase',
      wordBreak: 'break-word',
      '@media (max-width: 700px)': {
        left: '2px',
        width: '100%'
      }
    },
    '& p': {
      color: '#ffffff',
      width: '95%',
      fontSize: '1.75em',
      fontWeight: '200',
      textAlign: 'center',
      marginTop: '12px',
    },
    '& div': {
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      display: "flex",  
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "space-around",
      justifyContent: "center",
      alignItems: "center",
    }
  },
  opacity:{
    opacity: '0.5',
  },
  layer: {
    boxSizing: 'border-box',
    zIndex: '-1',
    backgroundColor: 'black',
    opacity: '0.3'
  },
  text: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 2%',
    '@media (min-width: 1024px)': {
      padding: '0 20%'
    }
  },
  textImage: {
    backgroundImage: (props) => (props.backgroundImage ? `linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 1)), url(${props.backgroundImage})` : "linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 1))"),
    height: 'auto',
    minHeight: '330px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
  }
}));

export default createStyles;
