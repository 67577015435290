import CourseCard from './CourseCard.jsx';
import { connect } from 'react-redux';
import { setFavorite, hasFavorite } from 'modules/Favorites/Favorites.ducks.js';

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.session.isAuthenticated,
    user: state.session.user
  };
};

const mapDispatchToProps = {
  setFavorite,
  hasFavorite
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseCard);
