import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import RegisterBoxForm from './RegisterBoxForm';
import { registerFormValidator } from './RegisterBoxForm.validator';

const RegisterBoxFormWrapper = (props) => {
  const initialValues = {
    cpfCNPJ: ''
  };

  const { history, isLoading, errors, handleClose } = props;

  const handleSubmit = (formValues) => {
    handleClose();
    history.push({
      pathname: '/cadastro',
      state: { cpfCNPJ: formValues.cpfCNPJ }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={registerFormValidator}
    >
      {(propsFormik) => (
        <RegisterBoxForm
          isLoading={isLoading}
          errors={errors}
          {...propsFormik}
        />
      )}
    </Formik>
  );
};

export default RegisterBoxFormWrapper;
