import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from 'components/ButtonPrimary';
import { Typography, Checkbox } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';

import createStyles from './RegisterInterestForm.styles';

const LoginForm = (props) => {
  const classes = createStyles();
  const {
    errors,
    touched,
    values,
    isSubmitting,
    isLoading,
    // externalErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    className,
    loginErrors,
    setFieldError,
    setFieldValue
  } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const visibilityIcon = isPasswordVisible ? <Visibility /> : <VisibilityOff />;

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Input
        placeholder='Ex.: João da Silva'
        label='Nome Completo'
        type='text'
        name='name'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.name}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        error={touched.name ? errors.name : null}
      />
        {/* <Input
        placeholder='Ex.: 123.456.789-00'
        mask={'999.999.999-99'}
        label='CPF'
        type='text'
        name='cpf'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.cpf}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        error={touched.cpf ? errors.cpf : null}
      /> */}
      <Input
        placeholder='(27) 99999-9999'
        label='Telefone'
        type='text'
        name='phone'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.phone}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        mask={'(99) 9 9999-9999'}
        error={touched.phone ? errors.phone : null}
      />
      <Input
        placeholder='Ex.: joao.silva@gmail.com'
        label='E-mail'
        name='email'
        type='text'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        // className={classes.input}
        customClasses={{ root: classes.input }}
        error={touched.email ? errors.email : null}
      />
    
      <div className={classes.actions}>
        <Typography
          className={classes.checkboxLabel}
          variant='body2'
          color='textSecondary'
        >
          <Checkbox
            size='medium'
            color='primary'
            inputProps={{ 'aria-label': 'quero receber ofertas' }}
            name='subscribeOffers'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.subscribeOffers}
            className={classes.checkbox}
          />
            Quero receber ofertas e novidades por e-mail, WhastApp e contato telefônico.
        </Typography>
      </div>
      {loginErrors && (
        <FormHelperText className={classes.errorsText} error>
          {loginErrors}
        </FormHelperText>
      )}
      <Button lowercase isLoading={isLoading} type='submit' width='100%'>
        Cadastrar interesse
      </Button>
    </form>
  );
};

export default LoginForm;
