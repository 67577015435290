import RegisterBox from './RegisterBox.jsx';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterBox);
