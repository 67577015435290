import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  bannerContent: {
    padding: '12rem 27rem',
    maxWidth: '44rem',
    [theme.breakpoints.down('md')]: {
      padding: '10rem 20rem'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6rem'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10%'
    }
  },
  categoryBanner: {
    marginBottom: "5px",
    // backgroundColor: 'black',
  },
  rootLoading: {
    height: 'calc(100vh - 7rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  linksBar: {
    marginTop: '3rem'
  },
  title: {
    color: theme.colors.blueCompany,
    textTransform: 'uppercase'
  },
  selectSearch: {
    marginTop: '3rem',
    marginBottom: '3rem',
    padding: ' 0 1rem'
  },
  cityFilterTitle: {
    marginTop: '3rem'
  },
  headerSelectSeach: {
    width: '30rem',
    marginLeft: '10rem',
    marginRight: '2rem',
    '@media (max-width: 450px)': {
      width: '100%'
    }
  },
}));

export default createStyles;
