import React, {useCallback, useEffect, useState} from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import Carousel from '../../components/Carousel/Carousel';
import Banner from 'components/Banner/Banner';
import ButtonPrimary from 'components/ButtonPrimary';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MainContainer from 'components/MainContainer';
import theme from 'App.theme.js';
import { useParams } from 'react-router-dom';
import { getCustomAttribute } from 'shared/util/ProductCustomAttribute';
import createStyles from './Category.styles';
import SelectSearch from 'components/SelectSearch';

const Category = (props) => {
  const { fetchCategory } = props;
  const { banner, cities, isLoading } = props.category;

  const classes = createStyles(props);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [orderBy, setOrderBy] = useState('more_relevant');
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const breadcrumbs = [
    { label: 'Início', to: '/' },
    { label: 'Categorias', to: '' },
    { label: banner.title, to: '' }
  ];

  const staticFilters = [
    { label: 'Menor preço', value: 'lower_price' },
    { label: 'Maior preço', value: 'higher_price' },
    { label: 'A-Z', value: 'a_z' },
    { label: 'Z-A', value: 'z_a' }
  ]

  function onOrderByChange (value) {

    cities.forEach(citie => {
      if (value === "lower_price")
        citie.products.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

      if (value === "higher_price")
        citie.products.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));

      if (value === "a_z")
        citie.products.sort((a, b) => {
          if(a.title < b.title)
            return -1;
          else if(a.title > b.title)
            return 1;
          return 0;
        });

      if (value === "z_a")
        citie.products.sort((a, b) => {
          if(a.title < b.title)
            return 1;
          else if(a.title > b.title)
            return -1;
          return 0;
        });

    })

    forceUpdate()
    setOrderBy(cities);
  }

  const { categoryId } = useParams();

  useEffect(() => {
    fetchCategory(categoryId);
  }, []);

  const convertToCarouselProduct = (products) => {
    const carouselProducts = products.map(product => {
      return {
        id: product.id,
        image: product.image,
        areaTag: product.areaTag,
        title: product.title,
        unit: product.unit,
        place: product.place,
        hasOpenChairs: product.hasOpenChairs,
        duration: parseInt(product.duration),
        classes: product.classes,
        price: product.price,
        presencialDistancia: product.presencialDistancia,
        parcels: product.parcels == 0 ? '' : product.parcels,
        favorite: product.favorite,
        discount: product.discount,
        priceWithDiscount: product.priceWithDiscount,
        sku: product.sku,
        startDate: product.startDate,
        nacionalUrl: product.nacionalUrl,
        descriptionPlan: product.descriptionPlan
    }});

    return carouselProducts;
  }

  return (
    <React.Fragment>
      <Breadcrumbs items={breadcrumbs} />

      {isLoading ? (
        <div className={classes.rootLoading}>
          <CircularProgress />
        </div>
      ) : (
        <React.Fragment>
          <div className={classes.categoryBanner}>
            <Banner
              opacity
              backgroundImage={banner.image}
              isCategory
              title={banner.title}
              description={banner.description}
            />
          </div>
          <div className={classes.filters}>
            <SelectSearch
                onSelectChange={onOrderByChange}
                className={classes.headerSelectSeach}
                placeholder='Ordenar por...'
                options={staticFilters.map(filter => {
                  return {
                    label: filter.label,
                    value: filter.value
                  };
                })}
                icon={'sort'}
                iconSize='small'
            />
          </div>
          {cities.map((city, index) => {
            return (
              <div key={city.id}>
                <MainContainer>
                  <Carousel
                    title={city.name}
                    subtitle={city.subtitle}
                    courses={convertToCarouselProduct(city.products)}
                    showAllBtn={<ButtonPrimary>VER TODOS</ButtonPrimary>}
                    showLoginBox={props.openLoginBox}
                    closeLoginBox={props.closeLoginBox}
                  />
                </MainContainer>
              </div>
            );
          })}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Category;
