
export const getRegistrationMock = () => {
    return {
        userRegistration: {
            fullName: 'João da Silva', 
            email: 'joao.silva@gmail.com', 
            cpf: '125.845.821-85', 
            birthDate: '11/04/1985',
            gender: 'male',
            mothersName: 'Ana Maria da Silva',
            phone1: '(27) 99999-8989', 
            phone2: '',
            receiveInfo: false
        }
    }
};