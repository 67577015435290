import { makeStyles } from '@material-ui/core/styles';

const createStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.white,
    boxShadow: props.isModal ? 'none' : '0px 4px 4px 0px rgba(0,0,0,0.25)',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 150ms ease-in-out',
    borderRadius: '5px',
    minWidth: '310px',

    '&:hover': {
      boxShadow: props.isModal ? 'none' : '0px 4px 15px 0px rgba(0,0,0,0.25)',

      '& $image': {
        transform: props.isModal ? 'none' : 'scale(1.03)'
      }
    }
  }),

  imageContainer: (props) => ({
    display: 'block',
    height: '15rem',
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    borderTopRightRadius: props.isModal ? '0px' : '5px',
    borderTopLeftRadius: props.isModal ? '0px' : '5px'
  }),
  image: () => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'all 200ms ease-in-out',

    '&:hover': {
      filter: 'brightness(90%)'
    }
  }),
  areaTagContainer: (props) => ({
    position: 'absolute',
    left: 0,
    backgroundColor: theme.colors.yellowTag,
    margin: '0',
    padding: '1rem',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    borderTopLeftRadius: props.isModal ? '0px' : '5px',
    cursor: 'default'
  }),
  nacionalContainer: {
    left: '0px',
    bottom: 0,
    cursor: 'default',
    margin: '0px',
    display: 'flex',
    padding: '0.25rem',
    zIndex: 1,
    position: 'absolute',
    alignItems: 'center',
    backgroundColor: '#1ea9e2',
  },    
  nacionalText: {
    display: 'block',
    textDecoration: 'none',
    fontSize: '1.4rem',
    fontWeight: '700',
    color: '#FFF',
    margin: '0',
    padding: '1px 5px 1px 5px'
  },
  areaTagIcon: {
    color: theme.colors.text.dark,
    marginRight: '1rem',
    cursor: 'pointer'
  },
  areaTagText: {
    display: 'block',
    textDecoration: 'none',
    fontSize: '1.4rem',
    fontWeight: '700',
    color: theme.colors.text.dark,
    margin: '0'
  },
  infoContainer: (props) => ({
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: props.isModal ? 'flex-start' : 'space-between',
    alignItems: 'flex-start',
    width: 'calc(100% - 4rem)',
    padding: '1.6rem 2rem',
    
  }),
  lowerInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%'
  },
  title: {
    margin: '0',
    color: theme.colors.text.dark,
    fontSize: '2.2rem',
    marginBottom: '1.5rem',
    cursor: 'pointer',
    height: '70px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  detailsList: {
    margin: '0',
    listStyle: 'none',
    padding: '0',
    marginBottom: '2rem',
    color: theme.palette.text.secondary
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',

    '& p': {
      fontSize: '1.6rem',
      margin: '0',
      display: 'inline-block',
      marginLeft: '1rem'
    }
  },
  priceContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  originalPrice: {
    textAlign: 'right',
    textDecoration: 'line-through'
  },
  price: {
    color: (props) => props.discount > 0 ? theme.colors.cyanYou : theme.colors.blueCompany,
    fontSize: '2.6rem',
    textAlign: 'right',
    fontWeight: 'bold'
  },
  paymentConditions: {
    marginBottom: '1rem',
    float: 'right'
  },
  span: {
    display: 'inline',
    fontSize: '1.4rem'
  },
  tagDiscount: {
    position: 'absolute',
    backgroundColor: theme.colors.blueLight,
    margin: '0',
    padding: '1rem',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
    bottom: 0,
    right: 0,
    '& p': {
      color: theme.colors.white
    }
  },
  noClassesAvailable: {
    textAlign: 'center',
    width: '100%',
    padding: '1.6rem 0'
  },
  modal:{
    zIndex: 894675239487634957823649587236945782639457862394578623945867
  }
}));

export default createStyles;
