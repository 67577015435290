import { getSearch } from 'api/Search';
import { getCities as _getCities} from 'api/City';


// Actions
const SEARCH_START = 'sesi/login/SEARCH_START';
const SEARCH_SUCCESS = 'sesi/login/SEARCH_SUCCESS';
const SEARCH_FAILURE = 'sesi/login/SEARCH_FAILURE';
const GET_CITY_SUCCESS = 'sesi/login/GET_CITY_SUCCESS';
const GET_CITY_FAILURE = 'sesi/login/GET_CITY_FAILURE';
const SEARCH_RESET = 'sesi/login/SEARCH_RESET';
const SEARCH_ADD_FILTER = 'sesi/search/SEARCH_ADD_FILTER';
const SEARCH_REMOVE_FILTER = 'sesi/search/SEARCH_REMOVE_FILTER';
const SET_SEARCH_FAVORITE = 'sesi/search/SET_SEARCH_FAVORITE'

// Reducer
const initialState = {
  staticFilters: [
    // { label: 'Mais relevantes', value: 'more_relevant' },
    { label: 'Menor preço', value: 'lower_price' },
    { label: 'Maior preço', value: 'higher_price' },
    { label: 'A-Z', value: 'a_z' },
    { label: 'Z-A', value: 'z_a' }
  ],
  totalSearchResults: 0,
  searchResults: [],
  isLoading: false,
  filters: {cidade:[], mostrar:[], turno:[], categoria: [], duracao: []},
  cities: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_START:
      return { ...state, isLoading: true };
    case SEARCH_SUCCESS:
      return { ...state, searchResults: action.payload, isLoading: false, totalSearchResults: action.payload.length };
    case GET_CITY_SUCCESS:
      return { ...state, cities: action.payload.data };
    case SEARCH_FAILURE:
    case GET_CITY_FAILURE:
      return { ...state, ...action.payload };
    case SEARCH_RESET:
      return { ...state, ...initialState };
    case SEARCH_ADD_FILTER:
      state.filters[action.payload.key].push(action.payload.filter)
      return { ...state, filters: {...state.filters} };
    case SEARCH_REMOVE_FILTER:
      state.filters[action.payload.key] = state.filters[action.payload.key].filter((filter) => { return filter.id !== action.payload.filter.id })
      return { ...state, filters: {...state.filters} };
    case SET_SEARCH_FAVORITE:
      let newSearchResults = state.searchResults.map(course => {
        if (course.sku === action.payload.sku) {
          course.favorite = action.payload.value;
        }
        return course;
      });
      return { ...state, searchResults: newSearchResults };
    default:
      return state;
  }
}

// Action Creators
export function searchStart() {
  return { type: SEARCH_START };
}

export function searchSuccess(data) {
  return { type: SEARCH_SUCCESS, payload: data };
}

export function searchFailure(data) {
  return { type: SEARCH_FAILURE, payload: data };
}

export function getCitiesSuccess(data) {
  return { type: GET_CITY_SUCCESS, payload: data };
}

export function getCitiesFailure(data) {
  return { type: GET_CITY_FAILURE, payload: data };
}

export function reset() {
  return { type: SEARCH_RESET };
}

// side effects, only as applicable
export const search = (
  searchTerm,
  searchParams,
  currentPage,
  itensPerPage,
  order,
  filters
) => {
  return async dispatch => {
    dispatch(searchStart());

    try {
      const separator = searchTerm ? '/' : '';
      const requestParams = `${separator}${searchTerm}${searchParams}`;
      const response = await getSearch(
        requestParams,
        currentPage,
        itensPerPage,
        order,
        filters
      );

      dispatch(searchSuccess(response));
    } catch (error) {
      dispatch(searchFailure(error));
    }
  };
};

export const addFilter = (key, filter) => {
  return { 
    type: SEARCH_ADD_FILTER, 
    payload: {key,filter}
  }
}

export const removeFilter = (key, filter) => {
  return { 
    type: SEARCH_REMOVE_FILTER, 
    payload: {key,filter}
  }
}

export const getCities = () => async dispatch => {
  try {
    const response = await _getCities();
    dispatch(getCitiesSuccess(response));
  } catch (error) {
    dispatch(getCitiesFailure(error));
  }
}

export const setFavoriteToCourse = (sku, value) => {
  return { type: SET_SEARCH_FAVORITE, payload: {sku, value} };
}